import React, { useState, useEffect, useRef } from "react";
import { IconButton, Badge, Paper, Typography, Box, Avatar, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

const NotificationBell = ({ notifications, clearNotification, clearAllNotifications }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);

  // Keep track of the previous notification count
  const prevNotificationCountRef = useRef(0);
  
  useEffect(() => {
    // Update the previous count reference
    prevNotificationCountRef.current = notifications.length;
  }, [notifications.length]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target) && !event.target.closest('.notification-bell-button')) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleClick = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const clickNotification = async (notification) => {
    await clearNotification(notification.id);
    window.location.href = notification.url;
  };

  if (notifications.length === 0) {
    return null;
  }

  return (
    <Box sx={{ position: "relative" }}>
      <IconButton
        className="notification-bell-button"
        color="inherit"
        onClick={handleClick}
      >
        <Badge 
          badgeContent={notifications.length} 
          color="error"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {isOpen && (
        <Paper
          ref={popoverRef}
          sx={{
            position: "absolute", 
            top: "100%",
            right: 0,
            width: "250px",
            backgroundColor: "white",
            bgcolor: "white",
            border: "5px solid grey.800",
            borderRadius: "8px", // Added border-radius for rounded corners
            zIndex: 1300,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 1, bgcolor: "grey.300", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
            <Typography variant="subtitle2" sx={{ color: "grey.800" }}>
              Notifications
            </Typography>
            <Box display="flex" alignItems="center">
              {notifications.length > 0 && (
                <Button
                  size="small"
                  startIcon={<DeleteSweepIcon fontSize="small" />}
                  onClick={clearAllNotifications}
                  sx={{ mr: 1, fontSize: '0.75rem' }}
                >
                  Clear All
                </Button>
              )}
              <IconButton onClick={handleClose} size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ maxHeight: 300, overflowY: "auto", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" }}>
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", p: 1, cursor: "pointer" }}
                  onClick={() => clickNotification(notification)}
                >
                  {notification.icon && (
                    <Avatar sx={{ mr: 1 }}>
                      {notification.icon}
                    </Avatar>
                  )}
                  <Typography variant="body2" sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                    {notification.text}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Typography variant="body2" color="text.secondary">
                  No notifications
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default NotificationBell;
