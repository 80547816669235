import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const AutoClearNotifications = ({ notifications, clearNotification }) => {
  const location = useLocation();
  const lastPathRef = useRef(location.pathname);
  
  const processNotifications = async (pathName) => {
    const currentPath = pathName.startsWith('/') ? pathName.substring(1) : pathName;
    const matchingNotifications = notifications.filter(notification => {
      if (!notification.url) return false;
      const notificationPath = notification.url.startsWith('/') 
        ? notification.url.substring(1) 
        : notification.url;
      return currentPath.startsWith(notificationPath);
    });
    
    if (matchingNotifications.length > 0) {
      for (const notification of matchingNotifications) {
        await clearNotification(notification.id);
      }
    }
  };
  
  useEffect(() => {
    const originalPushState = window.history.pushState;
    window.history.pushState = function(...args) {
      originalPushState.apply(this, args);
      if (window.location.pathname !== lastPathRef.current) {
        lastPathRef.current = window.location.pathname;
        processNotifications(window.location.pathname);
      }
    };
    return () => {
      window.history.pushState = originalPushState;
    };
  }, [notifications, clearNotification]);

  return null;
};

export default AutoClearNotifications;