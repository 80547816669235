import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Autocomplete,
  IconButton,
  Typography,
} from "@mui/material";
import { FixedSizeList } from "react-window";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

const AreaSelection = ({
  loadTerminal,
  setLoadTerminal,
  dischargeTerminal,
  setDischargeTerminal,
  extraLoadTerminal,
  setExtraLoadTerminal,
  extraDischargeTerminal,
  setExtraDischargeTerminal,
  isEditable,
  standardRoute = null,
  editingCargo = null,
}) => {
  const { t } = useTranslation();
  const [portOptions, setPortOptions] = useState([]);

  useEffect(() => {
    import("../../data/ports.json").then((module) => {
      setPortOptions(module.default);
      if (standardRoute) {
        if (standardRoute.loadTerminal) setLoadTerminal({ label: standardRoute.loadTerminal, category: module.default.find(p => p.label === standardRoute.loadTerminal).category });
        if (standardRoute.dischargeTerminal) setDischargeTerminal({ label: standardRoute.dischargeTerminal, category: module.default.find(p => p.label === standardRoute.dischargeTerminal).category });
        if (standardRoute.extraLoadTerminal) setExtraLoadTerminal({ label: standardRoute.extraLoadTerminal, category: module.default.find(p => p.label === standardRoute.extraLoadTerminal).category });
        if (standardRoute.extraDischargeTerminal) setExtraDischargeTerminal({ label: standardRoute.extraDischargeTerminal, category: module.default.find(p => p.label === standardRoute.extraDischargeTerminal).category });
      } else if (editingCargo) {
        if (editingCargo.loadTerminal) setLoadTerminal({ label: editingCargo.loadTerminal, category: module.default.find(p => p.label === editingCargo.loadTerminal).category });
        if (editingCargo.dischargeTerminal) setDischargeTerminal({ label: editingCargo.dischargeTerminal, category: module.default.find(p => p.label === editingCargo.dischargeTerminal).category });
        if (editingCargo.extraLoadTerminal) setExtraLoadTerminal({ label: editingCargo.extraLoadTerminal, category: module.default.find(p => p.label === editingCargo.extraLoadTerminal).category });
        if (editingCargo.extraDischargeTerminal) setExtraDischargeTerminal({ label: editingCargo.extraDischargeTerminal, category: module.default.find(p => p.label === editingCargo.extraDischargeTerminal).category });
      }
    });
  }, [standardRoute, editingCargo]);

  const renderRow = ({ index, style, props }) => {
    const { children } = props;
    const flatChildren = children.flatMap((child) => [
      child.props.children[0],
      ...child.props.children[1].props.children,
    ]);
    return (
      <div style={style} onMouseDown={(e) => e.preventDefault()}>
        {flatChildren[index]}
      </div>
    );
  };

  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ p: 1 }}>
        <Autocomplete
          id="load-terminal"
          options={portOptions}
          groupBy={(option) => option?.category ?? ""}
          getOptionLabel={(option) => option?.label ?? ""}
          value={loadTerminal}
          sx={{ flex: 1 }}
          onChange={(event, newValue) => setLoadTerminal(newValue)}
          renderInput={(params) => (
            <TextField {...params} label={t("trading_enter_cargo_load_terminal")} />
          )}
          ListboxComponent={(props) => (
            <FixedSizeList
              height={200}
              itemCount={props.children.flatMap(
                (child) => [child.props.children[0], ...child.props.children[1].props.children]
              ).length}
              itemSize={46}
              width="100%"
              {...props}
            >
              {({ index, style }) => renderRow({ index, style, props })}
            </FixedSizeList>
          )}
          disabled={!isEditable}
        />
      </Box>
      {extraLoadTerminal !== null && (
        <Box sx={{ p: 1, display: "flex", alignItems: "center" }}>
          <Autocomplete
            id="extra-load-terminal"
            options={portOptions.filter(
              (option) =>
                !loadTerminal?.label?.includes("AR") || !option.label?.includes("AR")
            )}
            groupBy={(option) => option?.category ?? ""}
            getOptionLabel={(option) => option?.label ?? ""}
            value={extraLoadTerminal}
            sx={{ flex: 1 }}
            onChange={(event, newValue) => setExtraLoadTerminal(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={t("trading_enter_cargo_extra_load_terminal")} />
            )}
            ListboxComponent={(props) => (
              <FixedSizeList
                height={200}
                itemCount={props.children.flatMap(
                  (child) => [child.props.children[0], ...child.props.children[1].props.children]
                ).length}
                itemSize={46}
                width="100%"
                {...props}
              >
                {({ index, style }) => renderRow({ index, style, props })}
              </FixedSizeList>
            )}
            disabled={!isEditable}
          />
          <IconButton onClick={() => setExtraLoadTerminal(null)} disabled={!isEditable}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {isEditable && extraLoadTerminal === null && extraDischargeTerminal === null && (
        <Box sx={{ p: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "primary.main",
              cursor: "pointer",
              "&:hover": { opacity: 0.8 },
            }}
            onClick={() => setExtraLoadTerminal("")}
          >
            <AddIcon sx={{ mr: 1 }} />
            <Typography>{t("trading_enter_cargo_add_load_terminal")}</Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ p: 1 }}>
        <Autocomplete
          id="discharge-terminal"
          options={portOptions}
          groupBy={(option) => option?.category ?? ""}
          getOptionLabel={(option) => option?.label ?? ""}
          value={dischargeTerminal}
          sx={{ flex: 1 }}
          onChange={(event, newValue) => setDischargeTerminal(newValue)}
          renderInput={(params) => (
            <TextField {...params} label={t("trading_enter_cargo_discharge_terminal")} />
          )}
          ListboxComponent={(props) => (
            <FixedSizeList
              height={200}
              itemCount={props.children.flatMap(
                (child) => [child.props.children[0], ...child.props.children[1].props.children]
              ).length}
              itemSize={46}
              width="100%"
              {...props}
            >
              {({ index, style }) => renderRow({ index, style, props })}
            </FixedSizeList>
          )}
          disabled={!isEditable}
        />
      </Box>
      {extraDischargeTerminal !== null && (
        <Box sx={{ p: 1, display: "flex", alignItems: "center" }}>
          <Autocomplete
            id="extra-discharge-terminal"
            options={portOptions.filter(
              (option) =>
                !dischargeTerminal?.label?.includes("AR") || !option.label?.includes("AR")
            )}
            groupBy={(option) => option?.category ?? ""}
            getOptionLabel={(option) => option?.label ?? ""}
            value={extraDischargeTerminal}
            sx={{ flex: 1 }}
            onChange={(event, newValue) => setExtraDischargeTerminal(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={t("trading_enter_cargo_extra_discharge_terminal")} />
            )}
            ListboxComponent={(props) => (
              <FixedSizeList
                height={200}
                itemCount={props.children.flatMap(
                  (child) => [child.props.children[0], ...child.props.children[1].props.children]
                ).length}
                itemSize={46}
                width="100%"
                {...props}
              >
                {({ index, style }) => renderRow({ index, style, props })}
              </FixedSizeList>
            )}
            disabled={!isEditable}
          />
          <IconButton onClick={() => setExtraDischargeTerminal(null)} disabled={!isEditable}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {isEditable && extraLoadTerminal === null && extraDischargeTerminal === null && (
        <Box sx={{ p: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "primary.main",
              cursor: "pointer",
              "&:hover": { opacity: 0.8 },
            }}
            onClick={() => setExtraDischargeTerminal("")}
          >
            <AddIcon sx={{ mr: 1 }} />
            <Typography>{t("trading_enter_cargo_add_discharge_terminal")}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AreaSelection; 
