import React from "react";
import "firebase/compat/auth";
import { Grid, Typography, useTheme, Box } from "@mui/material";
import Notifications from "../notification/Notifications";

function SpotbargeLayout({ children, title, isNotVerified, withNotifications = true }) {
  const theme = useTheme();

  return (
    <Grid
      sx={{
        width: "100%",
        height: "99.5%",
        p: isNotVerified ? 12 : 0,
        pt: isNotVerified ? 20 : 0,
      }}
    >
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h5"
            sx={{ color: theme.palette.primary.main, mb: 2, mt: isNotVerified ? -4 : 0.5 }}
          >
            {!isNotVerified && title}
          </Typography>
          {withNotifications && (
            <Notifications />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}

export default SpotbargeLayout;
