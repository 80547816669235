import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Alert,
  CircularProgress,
  MenuItem,
  Autocomplete,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Typography,
  Slider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Grid,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import GeneralErrorPage from "../../../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DateTime } from "luxon";
import { useTradingPost } from "../../../../request";
import { UserContext } from "../../../AuthGuard";
import { useNavigate } from "react-router-dom";
import AreaSelection from "./AreaSelection";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  boxShadow: "0 2px 12px rgba(0,0,0,0.08)",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#fff",
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#51f3d2",
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#fff",
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#51f3d2",
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  "&.MuiButton-contained": {
    backgroundColor: "#51f3d2",
    color: "#001557",
    "&:hover": {
      backgroundColor: "#3dd1b2",
    },
    "&:disabled": {
      backgroundColor: "#e0e0e0",
    },
  },
  "&.MuiButton-outlined": {
    borderColor: "#e0e0e0",
    color: "#666",
    "&:hover": {
      borderColor: "#000",
      backgroundColor: "transparent",
    },
    "&.MuiButton-outlinedError": {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      "&:hover": {
        borderColor: theme.palette.error.dark,
        backgroundColor: "transparent",
      },
    },
    "&.MuiButton-outlinedWarning": {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
      "&:hover": {
        borderColor: theme.palette.warning.dark,
        backgroundColor: "transparent",
      },
    },
  },
}));

export function getDaysFromUnitAndAmount(unit, amount) {
  switch (unit) {
    case "days":
      return amount;
    case "weeks":
      return amount * 7;
    case "months":
      return amount * 30;
    case "years":
      return amount * 365;
    default:
      return amount;
  }
}

export function getUnitAndAmountFromDays(days) {
  if (days % 365 === 0) {
    return [days / 365, "years"];
  } else if (days % 30 === 0) {
    return [days / 30, "months"];
  } else if (days % 7 === 0) {
    return [days / 7, "weeks"];
  } else {
    return [days, "days"];
  }
}

function COA({ editingCargo, onUpdate }) {
  const { t } = useTranslation();
  const [user] = useContext(UserContext);
  const [loadRange, setLoadRange] = useState([null, null]);
  const [durationAmount, setDurationAmount] = useState("");
  const [durationUnit, setDurationUnit] = useState("days");
  const [productType, setProductType] = useState("");
  const [product, setProduct] = useState("");
  const [loadTerminal, setLoadTerminal] = useState("");
  const [dischargeTerminal, setDischargeTerminal] = useState("");
  const [extraLoadTerminal, setExtraLoadTerminal] = useState(null);
  const [extraDischargeTerminal, setExtraDischargeTerminal] = useState(null);
  const [preCargo, setPreCargo] = useState([]);
  const [heating, setHeating] = useState(false);
  const [heatingTemperature, setHeatingTemperature] = useState("");
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: "", severity: "" });
  const [productOptions, setProductOptions] = useState([]);
  const [errors, setErrors] = useState([]);
  const [crew, setCrew] = useState([]);
  const [tonsPerDelivery, setTonsPerDelivery] = useState([1000, 12000]);
  const [volumePerMonth, setVolumePerMonth] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({ open: false, action: null });

  const post = useTradingPost();
  const navigate = useNavigate();
  useEffect(() => {
    import("../../data/products.json").then((module) => {
      setProductOptions(module.default);
      if (editingCargo) {
        setProduct(module.default.find(p => p.label === editingCargo.product));
      }
    });

    if (editingCargo) {
      if (editingCargo.loadStart && editingCargo.loadEnd) setLoadRange([DateTime.fromISO(editingCargo.loadStart), DateTime.fromISO(editingCargo.loadEnd)]);
      if (editingCargo.duration) {
        const [amount, unit] = getUnitAndAmountFromDays(editingCargo.duration);
        setDurationUnit(unit);
        setDurationAmount(amount);
      }
      if (editingCargo.productType) setProductType(editingCargo.productType);
      if (editingCargo.product) setProduct(editingCargo.product);
      if (editingCargo.preCargo) setPreCargo(editingCargo.preCargo);
      if (editingCargo.heating) setHeating(editingCargo.heating);
      if (editingCargo.heatingTemperature) setHeatingTemperature(editingCargo.heatingTemperature);
      if (editingCargo.comments) setComments(editingCargo.comments);
      if (editingCargo.crew) setCrew(editingCargo.crew);
      if (editingCargo.tonsPerDeliveryMin) setTonsPerDelivery([editingCargo.tonsPerDeliveryMin, editingCargo.tonsPerDeliveryMax]);
      if (editingCargo.volumePerMonth) setVolumePerMonth(editingCargo.volumePerMonth);
    }
  }, [editingCargo]);

  const handleClear = () => {
    setLoadRange([null, null]);
    setDurationAmount("");
    setDurationUnit("days");
    setProductType("");
    setProduct("");
    setLoadTerminal("");
    setDischargeTerminal("");
    setExtraLoadTerminal(null);
    setExtraDischargeTerminal(null);
    setPreCargo([]);
    setHeating(false);
    setHeatingTemperature("");
    setComments("");
    setErrors([]);
    setCrew([]);
    setTonsPerDelivery([1000, 12000]);
    setVolumePerMonth("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    setMessage({ text: "", severity: "" });
    setErrors([]);
    try {
      const loadStart = loadRange[0]?.toFormat('yyyy-MM-dd') || "";
      const loadEnd = loadRange[1]?.toFormat('yyyy-MM-dd') || "";
      const durationInDays = getDaysFromUnitAndAmount(durationUnit, durationAmount);
      const payload = {
        loadStart,
        loadEnd,
        duration: durationInDays,
        productType,
        product: product?.label,
        tonsPerDeliveryMin: tonsPerDelivery[0],
        tonsPerDeliveryMax: tonsPerDelivery[1],
        volumePerMonth,
        loadTerminal: loadTerminal?.label,
        dischargeTerminal: dischargeTerminal?.label,
        extraLoadTerminal: extraLoadTerminal?.label,
        extraDischargeTerminal: extraDischargeTerminal?.label,
        preCargo,
        heating,
        heatingTemperature,
        comments,
        crew,
      };
      if (editingCargo) {
        payload.id = editingCargo.id;
      }
      const res = await post("save-coa", payload);
      if (!res) {
        setMessage({
          text: "Failed to save cargo details. Please try again or contact us.",
          severity: "error",
        });
        return;
      } else if (res.error) {
        setMessage({ text: res.error, severity: "error" });
      } else if ("errors" in res) {
        const uniqueErrors = res.errors.reduce((acc, error) => {
          acc[error.path] = error;
          return acc;
        }, {});
        setErrors(Object.values(uniqueErrors));
      } else {
        setMessage({ text: "Cargo details saved successfully.", severity: "success" });
        handleClear();
        if (onUpdate) onUpdate();
      }
    } catch (err) {
      console.error(err);
      setMessage({
        text: "Failed to save cargo details. Please try again or contact us.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (setter) => (value) => (e) => {
    if (e.target.checked) {
      setter((prev) => [...prev, value]);
    } else {
      setter((prev) => prev.filter((v) => v !== value));
    }
  };

  const renderErrors = () => {
    if (errors.length === 0) return null;
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        <Typography variant="h6">There were errors with your submission:</Typography>
        <List dense disablePadding sx={{ padding: 0, margin: 0 }}>
          {errors.map((error, index) => (
            <ListItem key={index} sx={{ padding: '2px 0' }}>
              <ListItemText primary={`- ${error.msg}`} />
            </ListItem>
          ))}
        </List>
      </Alert>
    );
  };

  const handleConfirmDialogOpen = (action) => {
    setConfirmDialog({ open: true, action });
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialog({ open: false, action: null });
  };

  const handleConfirmDialogAction = async () => {
    if (!confirmDialog.action || !editingCargo) return;
    setLoading(true);
    setMessage({ text: "", severity: "" });
    try {
      const res = await post(confirmDialog.action, { id: editingCargo.id });
      if (!res) {
        setMessage({
          text: `Failed to ${confirmDialog.action}. Please try again or contact us.`,
          severity: "error",
        });
        return;
      } else if (res.error) {
        setMessage({ text: res.error, severity: "error" });
      } else {
        setMessage({ text: `${confirmDialog.action} successful.`, severity: "success" });
        handleClear();
        if (onUpdate) onUpdate();
      }
    } catch (err) {
      console.error(err);
      setMessage({
        text: `Failed to ${confirmDialog.action}. Please try again or contact us.`,
        severity: "error",
      });
    } finally {
      setLoading(false);
      handleConfirmDialogClose();
    }
  };

  const isEditable = !editingCargo || editingCargo.status === "New";
  const showDeleteButton = user.role === "admin" && editingCargo;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {message.text && (
        <Alert severity={message.severity} sx={{ mb: 2 }}>
          {message.text}
        </Alert>
      )}
      {renderErrors()}
      
      <Grid container spacing={3}>
        {/* Trip Basics Section */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" sx={{ color: "#001557", fontWeight: 600 }}>
              {`1. ${t("trading_enter_cargo_trip_basics")}`}
            </Typography>
            
            <DateRangePicker
              id="load-date"
              label={t("trading_enter_cargo_load_date")}
              sx={{ width: '100%' }}
              value={loadRange}
              onChange={(newValue) => setLoadRange(newValue)}
              slots={{ field: SingleInputDateRangeField }}
              name="allowedRange"
              minDate={isEditable ? DateTime.now() : undefined}
              disabled={!isEditable}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  id="duration-amount"
                  label={t("trading_enter_cargo_duration_amount")}
                  type="number"
                  value={durationAmount}
                  onChange={(e) => setDurationAmount(e.target.value)}
                  fullWidth
                  disabled={!isEditable}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  id="duration-unit"
                  label={t("trading_enter_cargo_duration_unit")}
                  select
                  value={durationUnit}
                  onChange={(e) => setDurationUnit(e.target.value)}
                  fullWidth
                  disabled={!isEditable}
                >
                  <MenuItem value="days">Days</MenuItem>
                  <MenuItem value="weeks">Weeks</MenuItem>
                  <MenuItem value="months">Months</MenuItem>
                  <MenuItem value="years">Years</MenuItem>
                </StyledTextField>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  id="product-type"
                  label={t("trading_enter_cargo_product_type")}
                  select
                  value={productType}
                  onChange={(e) => {
                    setProduct(null);
                    setProductType(e.target.value);
                  }}
                  fullWidth
                  disabled={!isEditable}
                >
                  <MenuItem value="cpp">CPP (clean petroleum products)</MenuItem>
                  <MenuItem value="dpp">DPP (dirty petroleum products)</MenuItem>
                </StyledTextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledAutocomplete
                  id="product-select"
                  options={
                    productType === "cpp"
                      ? productOptions.filter(option => option.type === "CPP" || option.type === "CPP/DPP")
                      : productType === "dpp"
                        ? productOptions.filter(option => option.type === "DPP" || option.type === "CPP/DPP")
                        : []
                  }
                  groupBy={(option) => option?.category ?? ""}
                  getOptionLabel={(option) => option?.label ?? ""}
                  disabled={!productType || !isEditable}
                  value={product}
                  onChange={(event, newValue) => setProduct(newValue)}
                  renderInput={(params) => (
                    <StyledTextField {...params} label={t("trading_enter_cargo_product")} />
                  )}
                />
              </Grid>
            </Grid>

            <Box>
              <Typography gutterBottom>{t("trading_enter_cargo_barge_tons_per_delivery")}</Typography>
              <Slider
                value={tonsPerDelivery}
                onChange={(e, newValue) => setTonsPerDelivery(newValue)}
                aria-labelledby="tons-per-delivery-slider"
                valueLabelDisplay="auto"
                step={500}
                marks
                min={1000}
                max={12000}
                valueLabelFormat={(value) => `${value} tons`}
                disabled={!isEditable}
              />
            </Box>

            <StyledTextField
              id="volume-per-month"
              label={t("trading_enter_cargo_volume_per_month")}
              type="number"
              value={volumePerMonth}
              onChange={(e) => setVolumePerMonth(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">t</InputAdornment>,
              }}
              disabled={!isEditable}
            />
          </StyledPaper>
        </Grid>

        {/* Area Selection Section */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" sx={{ color: "#001557", fontWeight: 600 }}>
              {`2. ${t("trading_enter_cargo_area")}`}
            </Typography>
            <AreaSelection
              loadTerminal={loadTerminal}
              setLoadTerminal={setLoadTerminal}
              dischargeTerminal={dischargeTerminal}
              setDischargeTerminal={setDischargeTerminal}
              extraLoadTerminal={extraLoadTerminal}
              setExtraLoadTerminal={setExtraLoadTerminal}
              extraDischargeTerminal={extraDischargeTerminal}
              setExtraDischargeTerminal={setExtraDischargeTerminal}
              isEditable={isEditable}
              editingCargo={editingCargo}
            />
          </StyledPaper>
        </Grid>

        {/* Crew Section */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" sx={{ color: "#001557", fontWeight: 600 }}>
              {`3. ${t("crew")}`}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={crew.includes("A1")}
                    onChange={handleCheckboxChange(setCrew)("A1")}
                    disabled={!isEditable}
                  />
                }
                label="A1 (16 hours sailing)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={crew.includes("A2")}
                    onChange={handleCheckboxChange(setCrew)("A2")}
                    disabled={!isEditable}
                  />
                }
                label="A2 (18 hours sailing)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={crew.includes("B")}
                    onChange={handleCheckboxChange(setCrew)("B")}
                    disabled={!isEditable}
                  />
                }
                label="B (24 hours sailing)"
              />
            </Box>
          </StyledPaper>
        </Grid>

        {/* Pre-cargo Section */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" sx={{ color: "#001557", fontWeight: 600 }}>
              {`4. ${t("pre_cargo")}`}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preCargo.includes("oxyfree")}
                    onChange={handleCheckboxChange(setPreCargo)("oxyfree")}
                    disabled={!isEditable}
                  />
                }
                label="Oxy Free"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preCargo.includes("bio")}
                    onChange={handleCheckboxChange(setPreCargo)("bio")}
                    disabled={!isEditable}
                  />
                }
                label="Bio Free"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preCargo.includes("gasfree")}
                    onChange={handleCheckboxChange(setPreCargo)("gasfree")}
                    disabled={!isEditable}
                  />
                }
                label="Gas Free"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preCargo.includes("jet_suitable")}
                    onChange={handleCheckboxChange(setPreCargo)("jet_suitable")}
                    disabled={!isEditable}
                  />
                }
                label="Jet Suitable"
              />
            </Box>
          </StyledPaper>
        </Grid>

        {/* Heating Section */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" sx={{ color: "#001557", fontWeight: 600 }}>
              {`5. ${t("heating")}`}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={heating}
                  onChange={(e) => setHeating(e.target.checked)}
                  disabled={!isEditable}
                />
              }
              label={t("trading_enter_cargo_heating_required")}
            />
            {heating && (
              <StyledTextField
                id="heating-temperature"
                label={t("trading_enter_cargo_heating_temperature")}
                type="number"
                value={heatingTemperature}
                onChange={(e) => setHeatingTemperature(e.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                }}
                disabled={!isEditable}
              />
            )}
          </StyledPaper>
        </Grid>

        {/* Comments Section */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" sx={{ color: "#001557", fontWeight: 600 }}>
              {`6. ${t("comments")}`}
            </Typography>
            <StyledTextField
              id="comments"
              label={t("comments")}
              multiline
              rows={4}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              fullWidth
              disabled={!isEditable}
            />
          </StyledPaper>
        </Grid>

        {/* Action Buttons */}
        <Grid item xs={12}>
          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
            {editingCargo && (editingCargo.status === "New" || editingCargo.status === "Pending") && (
              <StyledButton
                variant="outlined"
                color="warning"
                onClick={() => handleConfirmDialogOpen("cancel-cargo")}
              >
                {t("trading_enter_cargo_cancel")}
              </StyledButton>
            )}
            {showDeleteButton && (
              <StyledButton
                variant="outlined"
                color="error"
                onClick={() => handleConfirmDialogOpen("delete-cargo")}
              >
                {t("trading_enter_cargo_delete")}
              </StyledButton>
            )}
            {isEditable && (
              <StyledButton
                variant="contained"
                onClick={handleSubmit}
              >
                {t("trading_enter_cargo_submit")}
              </StyledButton>
            )}
            {editingCargo && editingCargo.offerId && (
              <>
                <StyledButton
                  variant="contained"
                  onClick={() => {
                    navigate(`/trading/oil/bid-log/${editingCargo.offerId}?back=/trading/oil/your-cargos/${editingCargo.id}`);
                  }}
                >
                  Bid log
                </StyledButton>
                <StyledButton
                  variant="contained"
                  onClick={() => {
                    navigate(`/trading/oil/fixture/${editingCargo.offerId}?back=/trading/oil/your-cargos/${editingCargo.id}`);
                  }}
                >
                  Fixture
                </StyledButton>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Dialogs */}
      <Dialog
        open={confirmDialog.open}
        onClose={handleConfirmDialogClose}
      >
        <DialogTitle>
          {confirmDialog.action === "delete-cargo" ? "Delete Cargo" : "Cancel Cargo"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {confirmDialog.action === "delete-cargo"
              ? "Are you sure you want to delete this cargo? This action cannot be undone."
              : "Are you sure you want to cancel this cargo?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleConfirmDialogClose}>
            Cancel
          </StyledButton>
          <StyledButton 
            onClick={handleConfirmDialogAction} 
            color={confirmDialog.action === "delete-cargo" ? "error" : "warning"}
          >
            Confirm
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default withErrorBoundary(COA, {
  fallback: <GeneralErrorPage />,
});
